.Nav_dropdownContainer {
  position: relative;
  display: inline-block;
}

@media all and (max-width: 1024px) {
  .Nav_dropdownContainer {
    display: block;
  }
}

.Nav_dropdown {
  display: none;
  position: absolute;
  background: white;
  border: 1px solid var(--br-grey);
  width: 250px;
  overflow: hidden;
}

@media all and (max-width: 1024px) {
  .Nav_dropdown {
    display: block;
    border: none;
    box-sizing: border-box;
    position: relative;
    max-height: 0;
    width: 100%;

    -o-transition: max-height 0.15s ease-in-out;

    transition: max-height 0.15s ease-in-out;
  }
}

.Nav_dropdown.Nav_expanded {
  display: block;
}

@media all and (max-width: 1024px) {
  .Nav_dropdown.Nav_expanded {
    display: block;
    position: relative;
    max-height: 200px;
  }
}

.Nav_dropdownLink:before {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 0;
  color: var(--input-grey) !important;
}

.Nav_dropdownLink.active:before {
  content: "\f077";
}

.Nav_dropdownContainer .Nav_dropdown a {
  color: var(--consumer-pink);
  display: block;
}

.Header_wrap .Nav_dropdownContainer .Nav_dropdown a {
  border-bottom: none;
  padding: 10px 20px;
  margin: 0;
}

.Header_wrap .Nav_dropdownContainer .Nav_dropdown a:hover {
  text-decoration: underline;
  border-bottom: none;
}
